<template>
<div class="main">
    <section class="section pt-0" ref="section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="page-content" v-if="loading">
                            <div class="d-flex text-white">
                                <i class="fas fa-spinner fa-spin fa-6x mx-auto mt-3 mb-4"></i>
                            </div>
                            <h4 class="text-center mb-3">Loading. . .</h4>
                        </div>
                        <div class="row match-height" v-else>
                            <div class="col-md-8 offset-md-2 position-relative">
                                <div class="chat-contianer" :class="mobile ? 'p-4' : 'px-5 py-3'">
                                    <transition-group name="fade" tag="div" class="row">
                                        <div class="chat-head" key="head" v-if="current_user">
                                            <router-link class="user-info" :to="'/user/'+current_user.id">
                                                <div class="position-relative">
                                                    <img v-if="current_user.image" :src="current_user.image" :alt="current_user.name">
                                                    <img v-else src="/app-assets/images/h-logo.png">
                                                    <span class="status online"></span>
                                                </div>
                                                <div class="wrapper">
                                                    <div class="name">{{ current_user.name }}</div>
                                                    <div class="price">{{ 'Chat @ '+this.user.currency.symbol + current_user.chat_price }}</div>
                                                </div>
                                                <!-- <div class="d-flex flex-column">
                                                    <div class="icons">
                                                        <div class="box"><i class="fas fa-eye"></i> {{ numberShort(current_user.prompts_sum_view_count,1) }}</div>
                                                        <div class="box"><i class="fas fa-heart"></i> {{ numberShort(current_user.likes_count,1) }}</div>
                                                        <div class="box"><i class="fas fa-tag"></i> {{ current_user.rank }}</div>
                                                    </div>
                                                </div> -->
                                            </router-link>
                                            <div class="flex-cc gap-1">
                                                <div id="timer"></div>
                                                <!-- <router-link to="" class="btn dark round small">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="me-0" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                                        <path d="M3.23047 6.55225C3.23047 5.41235 4.15454 4.48828 5.29444 4.48828H19.7305C20.0066 4.48828 20.2305 4.71214 20.2305 4.98828V6.61622C20.2305 7.72079 19.335 8.61622 18.2305 8.61622H5.29444C4.15454 8.61622 3.23047 7.69215 3.23047 6.55225Z" fill="#FF7A4A"/>
                                                        <path d="M3.23047 16.9986V6.55078C3.23047 7.69068 4.15454 8.61475 5.29444 8.61475H19.2305C20.335 8.61475 21.2305 9.51018 21.2305 10.6148V13.6247C21.2305 13.7075 21.1633 13.7747 21.0805 13.7747H18.2305C17.1259 13.7747 16.2305 14.6701 16.2305 15.7747V15.9026C16.2305 17.0072 17.1259 17.9026 18.2305 17.9026H21.0805C21.1633 17.9026 21.2305 17.9698 21.2305 18.0526V18.9986C21.2305 20.1031 20.335 20.9986 19.2305 20.9986H7.23047C5.02133 20.9986 3.23047 19.2077 3.23047 16.9986Z" fill="#FFECE2"/>
                                                        <path d="M15.2305 15.7734C15.2305 14.6689 16.1259 13.7734 17.2305 13.7734H21.0805C21.1633 13.7734 21.2305 13.8406 21.2305 13.9234V17.7514C21.2305 17.8342 21.1633 17.9014 21.0805 17.9014H17.2305C16.1259 17.9014 15.2305 17.006 15.2305 15.9014V15.7734Z" fill="#FF7A4A"/>
                                                        <rect x="5.23047" y="10.3594" width="6" height="1" rx="0.5" fill="#FF7A4A"/>
                                                    </svg>
                                                </router-link> -->
                                                <button type="button" @click="emitter.emit('showAVmodal')" class="btn light round small">
                                                    <i class="fas me-0 fa-phone"></i>
                                                </button>
                                                <button type="button" @click="emitter.emit('showAVmodal')" class="btn light round small">
                                                    <i class="fas me-0 fa-video"></i>
                                                </button>
                                                <button type="button" class="btn light round small" v-if="!current_session" @click="start_chat()">
                                                    <i class="fas fa-message"></i>
                                                    Start Chat
                                                </button>
                                                <button type="button" class="btn light round small" v-else @click="end_chat()">
                                                    <i class="fas fa-times"></i>
                                                    End Chat
                                                </button>
                                            </div>
                                        </div>
                                        <hr class="border-white" key="hr" v-if="current_user">
                                        <div class="col-12 msg-container" key="loading" v-if="c_loading">
                                            <div class="d-flex text-white">
                                                <i class="fas fa-spinner fa-spin fa-6x mx-auto mt-3 mb-4"></i>
                                            </div>
                                            <h4 class="text-center mb-3">Chat Loading. . .</h4>
                                        </div>
                                        <div class="col-12 msg-container" key="empty" v-if="!c_loading && current_chat_id && messages.length == 0">
                                            <div class="d-flex text-white">
                                                <i class="fas fa-frown fa-6x mx-auto mt-3 mb-4"></i>
                                            </div>
                                            <h4 class="text-center mb-3">No Messages Yet. . .</h4>
                                        </div>
                                        <transition-group tag="div" key="messages" class="col-12 scroll_container msg-container" v-if="current_chat_id && chat_reverse.length" name="fade">
                                            <div class="text-center" v-if="msg_loading">
                                                <i class="fas fa-spinner fa-spin fa-3x my-3 text-white"></i>
                                            </div>
                                            <div v-for="(m,ind) in chat_reverse" :key="ind">
                                                <div class="message-block" :class="{'chat-right' : m.senderId == user.id }">
                                                    <span v-if="m.messageType == 'string'" class="message">{{ m.message }}</span>
                                                    <div class="file-msg" v-else-if="m.messageType == 'file'">
                                                        <div class="head">
                                                            <span class="name">{{ m.fileName }}</span>
                                                            <span class="size">{{ Math.round(((m.fileSize/(1024)) + Number.EPSILON) * 100) / 100 }} KB</span>
                                                        </div>
                                                        <a :href="m.message" target="_blank" download="">
                                                        Download</a>
                                                    </div>
                                                    <span class="time">{{ $filters.UnixTS(m.messageTimestamp) }}</span>
                                                </div>
                                                <hr v-if="chat_reverse[ind+1] && chat_reverse[ind+1].sessionId != chat_reverse[ind].sessionId">
                                            </div>
                                        </transition-group>
                                        <form @submit.prevent="send_message" v-if="current_session"  key="footer" id="sendmessage" class="chat-footer">
                                            <div class="input-group input-group-merge me-1 form-send-message">
                                                <span class="input-group-text" @click="speech"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic cursor-pointer"><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg></span>
                                                <input type="text" :disabled="uploading" class="form-control message" v-model="text" placeholder="Type your message or use speech to text">
                                                <span class="input-group-text">
                                                    <label for="attach-doc" class="attachment-icon form-label mb-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image cursor-pointer text-secondary"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                                        <input :disabled="uploading" type="file" id="attach-doc" @change="handleFileUpload" hidden="">
                                                    </label>
                                                </span>
                                            </div>
                                            <button type="submit" class="btn orange-dark">
                                                <svg v-if="!uploading" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                                <i class="fas fa-spin fa-spinner me-0" v-else></i>
                                            </button>
                                        </form>
                                    </transition-group>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</div>

</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}
</style>
<script>
import { collection, query , onSnapshot, getCountFromServer, orderBy, or, getDocs, where, startAfter, setDoc, updateDoc, getDoc, limit, doc, addDoc, serverTimestamp} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default {
    name: "Chat",
    props: ['user', 'pandits'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            chats: [],
            messages: [],
            chat_id: false,
            session_id: false,
            chatData: null,
            docRef: null,
            current_session: null,
            timerInterval: null,
            startTime: '',
            expectedTimeInMinutes: '',
            remainingTimeInSeconds: '',
            endTime: '',
            loading: true,
            listening: false,
            chat_reverse: [],
            current_user: false,
            current_chat_id: false,
            msg_loading: false,
            c_loading: false,
            uploading: false,
            text: '',
            interval: '',
            last_limit: null,
        };
    },
    created() {
        if(localStorage.getItem('current_session')) {
            this.current_session = JSON.parse(localStorage.getItem('current_session'))
            this.timeJSON = JSON.parse(localStorage.getItem('timeJSON'))
            this.startTime = this.timeJSON.startTime
            this.expectedTimeInMinutes = this.timeJSON.expectedTimeInMinutes
            this.endTime = this.timeJSON.endTime
            this.remainingTimeInSeconds = this.timeJSON.remainingTimeInSeconds
            this.session_id = this.current_session.sessionId
            this.timer()
            
            this.emitter.emit('talk_section', true)
            this.emitter.emit('blog_section', true)
        }
        if (this.$route.params.id != undefined) {
            this.chat_id = this.$route.params.id ;
            // this.index(false)
            this.openchat(this.chat_id)
        }
        this.emitter.on('loggedOut', () => {
            this.token = null;
        })
    },
    beforeUnmount() {
        clearInterval(this.interval);
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
    },
    methods: {
        async openchat(id) {

            if(id == this.current_chat_id) return ;

            else this.messages = []

            this.c_loading = true;
            this.$Progress.start()

            this.current_chat_id = id;
            this.docRef = doc(db, "chats", id);
            var chat = await getDoc(this.docRef)
            this.chatData = chat.data() ;
            this.current_user = this.pandits.find(i => i.id == this.chatData.to.id)

            var colRef = query(collection(this.docRef, 'messages'), orderBy("messageTimestamp","desc"), limit(100))
            this.interval = onSnapshot(colRef, (querySnapshot) => {
                this.messages = []
                querySnapshot.forEach((document) => {
                    var docdata = document.data();
                    this.messages.push(docdata);
                });
                this.last_limit = querySnapshot.docs[querySnapshot.docs.length-1];
                this.c_loading = false
                this.loading = false
                this.chat_reverse = this.messages.reverse();
                this.seen_messages(this.current_chat_id)
                $('.msg-container').animate({ scrollTop: $('.msg-container')[0].scrollHeight }, 400);
                $('.msg-container')[0].addEventListener('scroll', this.chat_scroll);
            });

            this.c_loading = false
            this.loading = false
            this.$Progress.finish();
        },
        async start_chat() {
            if(this.user.wallet_amount <= this.current_user.chat_price) {
                toast.fire({icon: 'error', title: 'Low Balance. Unable to start Chat!'})
                return ;
            }

            this.session_id = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < 20) {
                this.session_id += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            this.startTime = new Date()
            this.expectedTimeInMinutes = Math.floor(this.user.wallet_amount/this.current_user.chat_price)
            this.endTime = new Date(this.startTime.getTime() + this.expectedTimeInMinutes * 60000);
            Swal.fire({
                title: "Sure to chat?",
                text: `You have ${this.user.currency.symbol+this.user.wallet_amount} in wallet to chat for next ${this.expectedTimeInMinutes} minutes!`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: "Yes, Start Chat!",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                // customClass: {
                //     confirmButton: 'main-button',
                //     cancelButton: 'main-button bg-danger ms-3'
                // },
                // buttonsStyling: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.remainingTimeInSeconds = this.expectedTimeInMinutes * 60;

                    this.timer()

                    var ip ;
                    delete axios.defaults.headers.common["X-Requested-With"];
                    await axios.get("https://api.ipify.org?format=json").then((res) => {
                        ip = res.data.ip
                    })

                    updateDoc(this.docRef, {
                        "currentSession" : this.session_id,
                    })

                    var str  = Date.now().toString() ;
                    this.current_session = {
                        startTimestamp: str,
                        endTimestamp: false,
                        userIp: ip,
                        deviceType: 3,
                        deviceDesc: navigator.userAgent,
                        durationInMinutes: 0,
                        totalPrice: null,
                        currency: this.user.currency.code,
                        userStartingWalletBalance: this.user.wallet_amount,
                        panditChatPrice: this.current_user.chat_price,
                        panditAudioPrice: this.current_user.audio_price,
                        panditVideoPrice: this.current_user.video_price,
                        sessionId : this.session_id,
                        chatId : this.current_chat_id,
                        recentSessionDeviceType : 3,
                    }
                    localStorage.setItem('current_session', JSON.stringify(this.current_session))
                }
            })
        },
        timer() {
            this.updateTimerDisplay(this.remainingTimeInSeconds);
            this.timerInterval = setInterval(async () => {
                this.remainingTimeInSeconds--;
                this.updateTimerDisplay(this.remainingTimeInSeconds);
                if (this.remainingTimeInSeconds === 300) { // 5 minutes before expiry
                    Swal.fire({
                        title: "Alert!",
                        text: "Chat will expire in 5 minutes.",
                        icon: "info",
                    });
                }
                if (this.remainingTimeInSeconds <= 0) {
                    clearInterval(this.timerInterval);
                    document.getElementById("timer").textContent = "";
                    await this.end_chat('timer_expired')
                }
            }, 1000);
        },
        updateTimerDisplay(remainingTime) {
            localStorage.setItem('timeJSON', JSON.stringify({
                startTime: this.startTime,
                expectedTimeInMinutes: this.expectedTimeInMinutes,
                endTime: this.endTime,
                remainingTimeInSeconds: this.remainingTimeInSeconds
            }))

            this.remainingTimeInSeconds = remainingTime
            var timeJSON = JSON.parse(localStorage.getItem('timeJSON'))
            timeJSON.remainingTimeInSeconds = this.remainingTimeInSeconds
            localStorage.setItem('timeJSON', JSON.stringify(timeJSON))
            var minutes = Math.floor(this.remainingTimeInSeconds / 60);
            var seconds = this.remainingTimeInSeconds % 60;
            var formattedMinutes = String(minutes).padStart(2, '0');
            var formattedSeconds = String(seconds).padStart(2, '0');
            $("#timer").html(formattedMinutes+':'+formattedSeconds);
        },
        send_message(){
            if(this.text == '') return

            const colRef = collection(this.docRef, 'messages')
            
            var str = Date.now().toString() ;
            setDoc(doc(colRef, str), {
                "senderId" : this.user.id,
                "message" : this.text,
                "sessionId" : this.session_id,
                "messageType" : 'string',
                "messageTimestamp" : str,
                "seen" : 0,
            })
            updateDoc(this.docRef, {
                "recentMsgBy" : this.user.id,
                "recentMsg" : this.text,
                "recentMsgType" : 'string',
                "recentMsgTimestamp" : str,
            })
            
            this.text = ''
            this.seen_messages()
            $('.msg-container .message-block:last-child')[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        },
        async seen_messages(id){
            var colRef = collection(this.docRef, 'messages')

            var q = query(colRef, where("seen", "==", 0), where("senderId", "!=", this.user.id));
            const qs = await getDocs(q);
            qs.forEach((ele) => {
                if(ele.data().by != this.user.id){
                    updateDoc(doc(colRef, ele.id), {
                        "seen" : 1
                    })
                }
            });
            // this.chats.find(i => i.chat_id == id).unseen = 0 
        },
        async end_chat(type = null){
            clearInterval(this.timerInterval)
            var currentTime = new Date();
            var timeLeftInSeconds = Math.round((new Date(this.endTime) - currentTime) / 1000);
            var timeLeftInMinutes = Math.floor(timeLeftInSeconds / 60);
            var timeConsumedInMinutes = this.expectedTimeInMinutes - timeLeftInMinutes;
            console.log(timeConsumedInMinutes, timeLeftInMinutes, timeLeftInSeconds, currentTime, this.endTime, this.startTime, this.expectedTimeInMinutes);
            var str = Date.now().toString() ;
            var current_session = JSON.parse(localStorage.getItem('current_session'))

            current_session.endTimestamp  = str,
            current_session.endBy  = type || 'customer',
            current_session.durationInMinutes = timeConsumedInMinutes,
            current_session.totalPrice = timeConsumedInMinutes*this.current_user.chat_price

            var data = {...current_session, ...this.chatData}

            await axios.post('/api/user/chat-complete', data, this.config).then((res) => {
                if(res.data.status == 'success') {
                    toast.fire({'icon': 'success', 'title': 'Chat Ended'}) ;
                    document.getElementById("timer").textContent = "";
                    this.current_session = null
                    this.endTime = null
                    this.startTime = null
                    this.expectedTimeInMinutes = null
                    this.remainingTimeInSeconds = null
                    localStorage.removeItem('current_session')
                    localStorage.removeItem('timeJSON')
                    this.interval()
                    this.emitter.emit('get_user')
                    Swal.fire({
                        title: "Completed!",
                        text: "Your chat has been ended.",
                        icon: "success",
                    })
                }
            });
        },
        async chat_scroll(e) {
            if($(e.target).scrollTop()==0) {
                this.msg_loading = true;
                var docRef = doc(db, "chats", this.current_chat_id);
                var q = query(collection(docRef, 'messages'), orderBy("messageTimestamp","desc"), startAfter(this.last_limit), limit(100))
                var docSnap =  await getDocs(q);
                if(docSnap.docs.length > 0) {
                    docSnap.forEach((data) => {
                        this.chat_reverse.unshift(data.data());
                    })
                    this.last_limit = docSnap.docs[docSnap.docs.length-1];
                }
                this.msg_loading = false;
                // $('.msg-container').animate({ scrollTop: $('.msg-container')[0].scrollHeight }, 400);
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if(file.size > 1024*1024) {
                toast.fire({
                    title: "Please Check!",
                    text: "File size must be less than 1 MB.",
                    icon: "info",
                })
                return ;
            }

            this.uploading = true
            
            const storageRef = ref(fireStorage, '/chats/'+this.current_chat_id+'/'+ file.name);

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(ref(storageRef)).then(downloadURL => {
                    const colRef = collection(this.docRef, 'messages')
                    var str  = Date.now().toString() ;
                    setDoc(doc(colRef, str), {
                        "senderId" : this.user.id,
                        "message" : downloadURL,
                        "messageType" : 'file',
                        "messageTimestamp" : str,
                        "sessionId" : this.session_id,
                        "fileType" : file.type,
                        "fileName" : file.name,
                        "fileSize" : file.size,
                        "seen" : 0,
                    })
                    updateDoc(this.docRef, {
                        "recentMsgBy" : this.user.id,
                        "recentMsg" : downloadURL,
                        "recentMsgType" : 'file',
                        "recentMsgTimestamp" : str,
                    })
                    $('.msg-container').animate({ scrollTop: $('.msg-container')[0].scrollHeight }, 400);
                    this.uploading = false
                });
            });
            
        },
        speech() {
            var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
            if (SpeechRecognition !== undefined && SpeechRecognition !== null) {
                var recognition = new SpeechRecognition();
                recognition.lang = 'en-IN' ;

                recognition.onspeechstart = function () {
                    this.listening = true;
                };
                if (this.listening === false) {
                    recognition.start();
                }
                recognition.onerror = function (event) {
                    this.listening = false;
                };
                recognition.onresult = function (event) {
                    this.text = event.results[0][0].transcript ;
                };
                recognition.onspeechend = function (event) {
                    this.listening = false;
                    recognition.stop();
                };
            }
        },
    },
};
</script>
<template>
    <div class="main">
        <section class="section orange" ref="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 spin-mandala">
                        <img src="app-assets/images/mandala.png" alt="Login Mandala" class="mandala">
                        <img src="app-assets/images/avatar.png" alt="Login Avatar" class="avatar">
                    </div>
                    <div class="col-md-6 offset-md-3">
                        <h2 class="title text-center mb-4">We are Coming Soon.</h2>
                        <h5 class="title text-center mb-3"></h5>
                        <router-link to="/become-a-scholar" class="btn orange-light col-md-8 mx-auto">Join Astrobharat as Astrologer</router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            horoscopes: ['Aries','Taurus','Gemini','Cancer','Leo','Virgo','Libra','Scorpio','Sagittarius','Capricorn','Aquarius','Pisces'],
        };
    },
    mounted() {
        this.$refs.section.scrollIntoView({behavior: 'smooth'})
    }
}
</script>
<template>
    <div class="main">
        <section class="section orange" ref="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card outline sticky p-lg-4" data-top="5%">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <div class="container-fluid" :class="{'d-block': !mobile}">
                                    <div class="flex-bc w-100 border-bottom pb-2">
                                        <h6>Filters</h6>
                                        <button class="btn orange-light small navbar-toggler" type="button" @click="showFilter=!showFilter">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </div>
                                    <transition name="slide-down">
                                        <div class="collapse navbar-collapse flex-column pt-3 d-block" v-if="!mobile || showFilter">
                                            <div class="form-group">
                                                <input type="search" v-model="form.search" @input="debouncedSearch" class="form-control" placeholder="Search here" />
                                            </div>
                                            <h6 class="w-100">
                                                Gender
                                                <small class="float-end cursor-pointer" v-if="form.genders.length != 2" @click="form.genders=['Male', 'Female']"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <ul class="navbar-nav flex-column me-auto mb-3">
                                                <li class="nav-item">
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="astro_notif" name="genders" :value="'Male'" v-model="form.genders" type="checkbox">
                                                        <label class="form-check-label" for="astro_notif">Male</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="astro_notif" name="genders" :value="'Female'" v-model="form.genders" type="checkbox">
                                                        <label class="form-check-label" for="astro_notif">Female</label>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h6 class="w-100" v-if="astro_rooms.length">
                                                Rooms
                                                <small class="float-end cursor-pointer" v-if="form.rooms.length" @click="form.rooms=[]"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <ul class="navbar-nav flex-column me-auto mb-3">
                                                <li class="nav-item" v-for="c in astro_rooms" :key="c">
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="astro_notif" name="rooms" :value="c" v-model="form.rooms" type="checkbox">
                                                        <label class="form-check-label" for="astro_notif">{{ c }}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h6 class="w-100" v-if="skills.length">
                                                Skills
                                                <small class="float-end cursor-pointer" v-if="form.skills.length" @click="form.skills=[]"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <div class="w-100 form-group">
                                                <v-select :options="skills" v-model="form.skills" multiple :placeholder="'Select one to filter'" class="h-auto bg-white" :clearable="false"></v-select>
                                            </div>
                                            <!-- <ul class="navbar-nav flex-column me-auto mb-3">
                                                <li class="nav-item" v-for="c in skills" :key="c">
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="astro_notif" name="skills" :value="c" v-model="form.skills" type="checkbox">
                                                        <label class="form-check-label" for="astro_notif">{{ c }}</label>
                                                    </div>
                                                </li>
                                            </ul> -->
                                            <h6 class="w-100" v-if="astro_langs.length">
                                                Languages
                                                <small class="float-end cursor-pointer" v-if="form.languages.length" @click="form.languages=[]"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <div class="w-100 form-group">
                                                <v-select :options="astro_langs" v-model="form.languages" multiple :placeholder="'Select one to filter'" class="h-auto bg-white" :clearable="false"></v-select>
                                            </div>
                                            <!-- <ul class="navbar-nav flex-column me-auto mb-3">
                                                <li class="nav-item" v-for="c in astro_langs" :key="c">
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="astro_notif" name="languages" :value="c" v-model="form.languages" type="checkbox">
                                                        <label class="form-check-label" for="astro_notif">{{ c }}</label>
                                                    </div>
                                                </li>
                                            </ul> -->
                                        </div>
                                    </transition>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <!-- <transition-group tag="div" class="row" appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave"> -->
                        <transition-group tag="div" class="row" appear name="fade">
                            <astrologer-card :user="user" :data="item" :data-index="index" :class="index == (astrologers.length - last_item) ? 'last_item' : ''" v-for="item,index in astrologers" :key="index"></astrologer-card>
                        </transition-group>
                        <transition name="fade" tag="div" class="row">
                            <div class="col-12" v-if="loading">
                                <loader />
                            </div>
                            <div class="col-lg-8 offset-lg-2" v-else-if="!loading && !astrologers.length">
                                <div class="card">
                                    <div class="text-center row match-height">
                                        <div class="col-lg-6 spin-mandala ty">
                                            <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                            <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                        </div>
                                        <div class="col-lg-6" v-if="!mobile">
                                            <img src="/app-assets/images/avatar.png" alt="Mandala">
                                        </div>
                                    </div>
                                    <p class="desc">Oops, We were unable to find records!</p>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user', 'skills', 'astro_langs', 'astro_rooms'],
    data() {
        return {
            mobile: this.isMobile(),
            action: this.$route.query?.action,
            pagination: null,
            pageNo: 1,
            form: new Form({
                search: '',
                genders: ['Male', 'Female'],
                experiences: [],
                languages: [],
                skills: [],
                rooms: [],
            }),
            showFilter: false,
            loading: false,
            loader_text: 'Loading . . .',
            last_item: this.isMobile() ? 4 : 1,
            astrologers: [],
            l_count:0,
        };
    },
    created() {
        this.debouncedSearch = this.$debounce(this.index, 500);
        this.emitter.emit('talk_section', true)
        this.index()
        this.emitter.on("view_more", () => {
            if(this.$route.name == 'Astrologers') {
                this.view_more()
            }
        });
    },
    watch: {
        'form.genders': {
            handler() { this.pageNo = 1;  this.index(); }, deep: true
        },
        'form.experiences': {
            handler() { this.pageNo = 1;  this.index(false); }, deep: true
        },
        'form.skills': {
            handler() { this.pageNo = 1;  this.index(false); }, deep: true
        },
        'form.rooms': {
            handler() { this.pageNo = 1;  this.index(false); }, deep: true
        },
        'form.languages': {
            handler() { this.pageNo = 1;  this.index(false); }, deep: true
        },
    },
    beforeUnmount() {
        this.emitter.emit('talk_section', false)
    },
    methods: {
        async index(scroll = true) {
            this.loading = true
            await axios.post('/api/user/astrologers/get?page='+this.pageNo, this.form).then((res) => {
                this.loading = false
                var resdata = Object.values(res.data.astrologers.data)
                if(this.pageNo != 1) this.astrologers = [...this.astrologers, ...resdata];
                else this.astrologers = resdata
                if(scroll)
                    this.emitter.emit('scroll_section')
                this.l_count = resdata.length
            }).catch((error) => {
                this.loading = false
            })
        },
        view_more() {
            if(this.l_count > 0) {
                this.pageNo++
                this.index(false)
            }
        },
        onBeforeEnter(el) {
            el.style.opacity = 0
        },
        onEnter(el, done) {
            gsap.to(el, {
                opacity: 1,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        onLeave(el, done) {
            gsap.to(el, {
                opacity: 0,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
    },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.3s linear;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform-origin: center;
    filter: blur(10px);
    transform: scale(0);
}
</style>